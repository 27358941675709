export default {
  dateFormat: 'YYYY-MM-DD',
  currency: '€',
  defaultScenario: 'scenario.type.long-life',
  disabledRetirementScenario: 'scenario.type.death',
  defaultRetirementType: 'retirement.early_retirement',
  defaultRetirementAge: 67,
  highLowRetirementType: 'retirement.type.high_low',
  incomeColor: '#386DFF',
  difference: 5, // retirement high-low section
  isCompletenessActive: true,
  isChartVisible: false,
  expenseColor: '#F24452',
  cjCarsLabel: [
    {
      label: 'cj.cars-no-cars',
      value: 0,
    },
    {
      label: 'cj.cars-1-car',
      value: 1,
    },
    {
      label: 'cj.cars-2-cars',
      value: 2,
    },
  ],
  cjChildrenLabel: [
    {
      label: 'cj.children-no-children',
      value: 0,
    },
    {
      label: 'cj.children-1-child',
      value: 1,
    },
    {
      label: 'cj.children-2-children',
      value: 2,
    },
    {
      label: 'cj.children-3-children',
      value: 3,
    },
    {
      label: 'cj.children-4-children',
      value: 4,
    },
    {
      label: 'cj.children-5-children',
      value: 5,
    },
  ],
};
