import * as config from 'utils/config';

//retirement types
export const early = 'retirement.type.part_time';
export const part_time = 'retirement.type.part_time';
export const high_low = 'retirement.type.high_low';

//scenarios types
export const long_life = 'scenario.type.long-life';
export const death = 'scenario.type.death';
export const partner_death = 'scenario.type.death-partner';

export const mockScenarios = [
  {
    code: 'scenario.type.long-life',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: true,
    id: 46,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.high_low',
    valueRetirementType: 5,
  },
  {
    code: 'scenario.type.death',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: false,
    id: 47,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.early_retirement',
    valueRetirementType: 67,
  },
  {
    code: 'scenario.type.death-partner',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: false,
    id: 48,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.early_retirement',
    valueRetirementType: 67,
  },
];

export const partnerDeathScenario = [
  {
    code: 'scenario.type.death-partner',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: false,
    id: 48,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.early_retirement',
    valueRetirementType: 67,
  },
];

export const deathMockScenario = [
  {
    code: 'scenario.type.death',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: false,
    id: 47,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.early_retirement',
    valueRetirementType: 67,
  },
];

export const retirementResponse = {
  code: 'scenario.type.long-life',
  defaultPensionStartDate: '2037-03-01T22:00:00Z',
  defaultRetirementAge: 67,
  flagNibudPopUp: true,
  id: 46,
  partTimeIncomePercentage: null,
  partTimePensionPercentage: null,
  retirementAge: 67,
  retirementType: 'retirement.type.early_retirement',
  valueRetirementType: 67,
};

export const longLifeMockScenario = [
  {
    code: 'scenario.type.long-life',
    defaultPensionStartDate: '2037-03-01T22:00:00Z',
    defaultRetirementAge: 67,
    flagNibudPopUp: true,
    id: 46,
    partTimeIncomePercentage: null,
    partTimePensionPercentage: null,
    retirementAge: 67,
    retirementType: 'retirement.type.high_low',
    valueRetirementType: 5,
  },
];

export const yearsLoadSuccess = [
  {
    year: 2037,
    age: 67,
    partnerAge: 55,
    totalIncome: 7406.43,
    totalExpense: 1621,
    difference: 5785.43,
    box1Tax: 440.01,
    box3Tax: 0,
    taxReductions: 359.21,
    totalTaxes: 80.8,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 5824.41,
        net: 5824.41,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1662.82,
        net: 1582.02,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 2020.33,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2038,
    age: 68,
    partnerAge: 56,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2039,
    age: 69,
    partnerAge: 57,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2040,
    age: 70,
    partnerAge: 58,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2041,
    age: 71,
    partnerAge: 59,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2042,
    age: 72,
    partnerAge: 60,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2043,
    age: 73,
    partnerAge: 61,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2044,
    age: 74,
    partnerAge: 62,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2045,
    age: 75,
    partnerAge: 63,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2046,
    age: 76,
    partnerAge: 64,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2047,
    age: 77,
    partnerAge: 65,
    totalIncome: 8616.03,
    totalExpense: 1621,
    difference: 6995.03,
    box1Tax: 596.47,
    box3Tax: 0,
    taxReductions: 419.75,
    totalTaxes: 176.71,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 1803.45,
        net: 1626.74,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2048,
    age: 78,
    partnerAge: 66,
    totalIncome: 9281.15,
    totalExpense: 1621,
    difference: 7660.15,
    box1Tax: 832.62,
    box3Tax: 0,
    taxReductions: 460.25,
    totalTaxes: 372.38,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 2664.23,
        net: 2291.86,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2049,
    age: 79,
    partnerAge: 67,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2050,
    age: 80,
    partnerAge: 68,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2051,
    age: 81,
    partnerAge: 69,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2052,
    age: 82,
    partnerAge: 70,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2053,
    age: 83,
    partnerAge: 71,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2054,
    age: 84,
    partnerAge: 72,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2055,
    age: 85,
    partnerAge: 73,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2056,
    age: 86,
    partnerAge: 74,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2057,
    age: 87,
    partnerAge: 75,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
  {
    year: 2058,
    age: 88,
    partnerAge: 76,
    totalIncome: 9401.77,
    totalExpense: 1621,
    difference: 7780.77,
    box1Tax: 1148.86,
    box3Tax: 0,
    taxReductions: 426.66,
    totalTaxes: 722.2,
    incomes: [
      {
        category: 'inheritance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'house',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'investments',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'savings',
        description: null,
        gross: 6989.29,
        net: 6989.29,
      },
      {
        category: 'lifeInsurance',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'income',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'pension',
        description: null,
        gross: 0,
        net: 0,
      },
      {
        category: 'aow',
        description: null,
        gross: 3134.68,
        net: 2412.48,
      },
    ],
    expenses: [
      {
        category: 'living',
        description: 'woonlasten',
        value: 382,
      },
      {
        category: 'loan',
        description: 'loan',
        value: 0,
      },
      {
        category: 'housing',
        description: 'nibud.housing',
        value: 579,
      },
      {
        category: 'insurances',
        description: 'nibud.insurances',
        value: 314,
      },
      {
        category: 'transportation',
        description: 'nibud.transportation',
        value: 79,
      },
      {
        category: 'healthcare',
        description: 'nibud.healthcare',
        value: 88,
      },
      {
        category: 'telecom',
        description: 'nibud.telecom',
        value: 123,
      },
      {
        category: 'other',
        description: 'nibud.other',
        value: 56,
      },
      {
        category: 'savings',
        description: null,
        value: 0,
      },
      {
        category: 'lifeInsurance',
        description: null,
        value: 0,
      },
    ],
  },
];

export const expectResultLoadYears = [
  {
    category: 'aow',
    description: null,
    gross: 1662.82,
    net: 1582.02,
  },
  {
    category: 'savings',
    description: null,
    gross: 5824.41,
    net: 5824.41,
  },
  {
    category: 'savings',
    description: null,
    value: 2020.33,
  },
  {
    category: 'other',
    description: 'nibud.other',
    value: 56,
  },
  {
    category: 'telecom',
    description: 'nibud.telecom',
    value: 123,
  },
  {
    category: 'healthcare',
    description: 'nibud.healthcare',
    value: 88,
  },
  {
    category: 'transportation',
    description: 'nibud.transportation',
    value: 79,
  },
  {
    category: 'insurances',
    description: 'nibud.insurances',
    value: 314,
  },
  {
    category: 'housing',
    description: 'nibud.housing',
    value: 579,
  },
  {
    category: 'living',
    description: 'woonlasten',
    value: 382,
  },
];

export const baseDefault = {
  aowItems: [],
  defaultRetirementAge: 67,
  houseHoldItem: {},
  houseItem: {},
  incomeItem: {},
  incomeTypes: [],
  inheritanceItem: {},
  inheritanceTypes: [],
  insuranceItem: {},
  insuranceTaxTypes: [],
  insuranceTypes: [],
  investmentsItem: {},
  isChartVisible: false,
  isCompletenessActive: true,
  isRetirementVisible: true,
  loanItem: {},
  loanTypes: [],
  monthOverview: [],
  mortgageItem: {},
  mortgageTypes: [],
  nibudItems: [],
  overviewReport: {},
  pensionItem: {},
  pensionTypes: [],
  rentItem: {},
  retirementPercentagesInputs: {
    incomePercentage: 50,
    pensionPercentage: 50,
  },
  savingsItem: {},
  scenarios: [],
  selectedCurrentYear: {},
  selectedHighLow: 5,
  selectedRetirementType: config.default.defaultRetirementType,
  selectedRetirementYear: 67,
  shouldShowPrepension: false,
};

export const houseIncomeResponse = {
  id: 503,
  name: 'house',
  wozValue: 10000,
  retirementMortgageValue: 0,
  retirementPercentage: 20,
  totalHouse: 10000,
  totalAllocated: 7.35,
  items: null,
};

export const houseIncomeResponseParsed = {
  id: 503,
  name: 'house',
  wozValue: 10000,
  retirementMortgageValue: 0,
  retirementPercentage: 20,
  totalHouse: 10000,
  totalAllocated: 7,
  items: null,
};

export const inheritanceItemResponse = {
  id: 504,
  name: 'inheritance',
  retirementPercentage: 90,
  monthlyAmountAfterRetirement: 2446.54,
  totalAllocated: null,
  items: [{ id: 148, amount: 1232332, type: 3 }],
};

export const inheritanceItemResponseParsed = {
  id: 504,
  name: 'inheritance',
  retirementPercentage: 90,
  monthlyAmountAfterRetirement: 2447,
  totalAllocated: null,
  items: [{ id: 148, amount: 1232332, type: 3 }],
};

export const retirementPercentagesInput = {
  incomePercentage: 45,
  pensionPercentage: 55,
};

export const retirementPercentagesAPIResponse = {
  partTimeIncomePercentage: 45,
  partTimePensionPercentage: 55,
};
