import { notif } from '../../components/common/NotifToastr/Notif';

const nodeEnv =
  typeof process.env.NODE_ENV !== 'undefined'
    ? process.env.NODE_ENV
    : 'development';

/**
 * Get status code from [error]
 * @param {Error} [error] Axios error
 * @returns {number} HTTP status code
 */
export const getStatusCodeFromError = error => {
  if (typeof error === 'undefined') {
    return null;
  }
  if (typeof error.response !== 'undefined') {
    return error.response.status;
  } else if (typeof error.status !== 'undefined') {
    return error.status;
  }
  return null;
};

/**
 * From [error] returns notification transation key base on status code
 * @param {Error} [error] The error
 * @returns {string} react-intl translation key name
 */
export const getNotifFromError = error => {
  if (typeof error === 'undefined') {
    return false;
  }

  let status,
    data,
    notification_message = '';

  if (typeof error.response !== 'undefined') {
    data = error.response.data;
  }
  status = getStatusCodeFromError(error);
  if (typeof error.response === 'undefined') {
    if (error.toString().includes('Error: Network Error')) {
      // api might be under maintenance or cannot respond
      notif.error('notifications.api.unavailable');
      return {
        message: 'notifications.api.unavailable',
        http_code: status,
      };
    }
  }
  switch (status) {
    case 400:
      if (data && data.errorCode === 'year.file.invalid.range') {
        notification_message = 'error.file.upload.pension.outofrange';
      }
      break;
    case 401:
      if (data && data.error === 'invalid_token') {
        notification_message = 'notifications.auth.invalidToken';
      }
      if (data && data.error === 'unauthorized') {
        notification_message = 'notifications.auth.accessDenied';
      }
      break;
    case 404:
      notification_message = 'notifications.client.notfound';
      break;
    case 422:
      notification_message = 'notifications.api.unprocessable_entity';
      break;
    case 502:
    case 503:
      notification_message = 'notifications.api.unavailable';
      break;
    case 504:
      if (nodeEnv === 'development') {
        console.log(`error ${status}`, error.response);
      }
      notification_message = 'notifications.api.unavailable';
      break;
    case 500:
      if (data && data.errorCode === 'file.invalid.format') {
        notification_message = 'error.file.upload.invalid';
      } else {
        notification_message = 'notifications.api.exception';
      }
      break;
    default:
      notification_message = 'notifications.api.exception';
      break;
  }
  return {
    message: notification_message,
    http_code: status,
  };
};

/**
 * Get notifications from error and send it to UI using /NotifToastr/Notif component
 * @param {Error} error
 */
export const sendNotifFromError = error => {
  const { message, http_code } = getNotifFromError(error);

  if (http_code === 422) {
    notif.warn(message);
  } else {
    notif.error(message);
  }
};
