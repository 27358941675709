export const SET_AUTH_STEP = 'SET_AUTH_STEP';
export const LOADING = 'LOADING';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const RESET_NIBUD_FLAG_SUCCESS = 'RESET_NIBUD_FLAG_SUCCESS';
export const LOAD_PERSONS_SUCCESS = 'LOAD_PERSONS_SUCCESS';
export const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR';
export const RESET_CURRENT_YEAR = 'RESET_CURRENT_YEAR';
export const SET_SELECTED_SCENARIO = 'SET_SELECTED_SCENARIO';
export const GENERATE_TAX_FILES = 'GENERATE_TAX_FILES_SUCCESS';
