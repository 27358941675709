import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Footer extends React.Component {
  render() {
    const currentLang = this.props.intl.messages;
    return (
      <div className="footer-bottombar footer--app">
        <span>
          {currentLang['footer.copyright']} |
          <a ta-id="footer_terms" href="/terms-of-use" target="_blank" rel="noopener noreferrer">
            {currentLang['footer.user-info']}
          </a>
        </span>
        <span>
          {currentLang['footer.rtb']}
          <a
            ta-id="footer_redtoblue_site"
            href="https://red-to-blue.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Red To Blue
          </a>
        </span>
      </div>
    );
  }
}

Footer.propTypes = {
  intl: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  intl: state.intl,
});

export default connect(
  mapStateToProps,
  null
)(Footer);
