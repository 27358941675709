import * as types from 'action-types/admin';
import { combineReducers } from 'redux';
import { sortAndParseResult } from '../../services/utils';
import moment from 'moment';

export function organisations(state = {}, action) {
  switch (action.type) {
    case types.LOAD_ORGANISATIONS_SUCCESS:
      return action.organisationsData;
    default:
      return state;
  }
}

export function parentOrganisations(state = {}, action) {
  switch (action.type) {
    case types.LOAD_PARENT_ORGANISATIONS_SUCCESS:
      return action.parentOrganisationsData;
    default:
      return state;
  }
}

export function usersList(state = {}, action) {
  switch (action.type) {
    case types.LOAD_USERSLIST_SUCCESS:
      return action.usersData;
    default:
      return state;
  }
}

export function usersImportList(state = {}, action) {
  switch (action.type) {
    case types.LOAD_USERS_BULK_IMPORT_SUCCESS:
      return action.usersBulkImport;
    default:
      return state;
  }
}

export function usersImportDetailsList(state = {}, action) {
  switch (action.type) {
    case types.LOAD_USERS_BULK_IMPORT_DETAILS_SUCCESS:
      return action.bulkImportDetails;
    default:
      return state;
  }
}

export function bulkImportResponse(state = {}, action) {
  switch (action.type) {
    case types.CREATE_USERS_BULK_IMPORT_SUCCESS:
      return action.data;
    case types.CLEAR_USERS_BULK_IMPORT:
      return {};
    case types.LOAD_USERS_BULK_IMPORT_FAIL:
      if (
        action.validationErrors.response.data.hasOwnProperty('invalidParams')
      ) {
        const responseData = action.validationErrors.response.data;
        const parsedData = {
          ...responseData,
          invalidParams: sortAndParseResult(responseData.invalidParams),
        };
        return parsedData;
      }
      return action.validationErrors.response.data;
    default:
      return state;
  }
}

export function shouldReloadUsersList(state = false, action) {
  switch (action.type) {
    case types.LOAD_USERSLIST_SUCCESS:
      return false;
    case types.SEND_EDITED_USER_DATA_SUCCESS:
    case types.DELETE_COGNITO_USER_SUCCESS:
    case types.ENABLE_COGNITO_USER_SUCCESS:
    case types.DISABLE_COGNITO_USER_SUCCESS:
    case types.ADD_SINGLE_USER_SUCCESS:
    case types.ADD_MULTIPLE_USERS_SUCCESS:
    case types.USERS_SORT:
    case types.DELETE_MULTIPLE_USERS_SUCCESS:
    case types.DISABLE_MULTIPLE_USERS_SUCCESS:
    case types.ENABLE_MULTIPLE_USERS_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function closeBottombar(state = false, action) {
  switch (action.type) {
    case types.OPEN_BOTTOMBAR:
      return false;
    case types.CLOSE_BOTTOMBAR:
      return true;
    default:
      return state;
  }
}

export function editUser(state = {}, action) {
  switch (action.type) {
    case types.LOAD_EDIT_USER_SUCCESS: {
      const apiBirthDate = action.editUserData.birthDate;
      const formatApiBirthDate = moment(apiBirthDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD[T]HH:mm:ss[Z]'
      );
      const newActionData = Object.assign({}, action.editUserData);
      newActionData.birthDate = formatApiBirthDate;
      return newActionData;
    }
    default:
      return state;
  }
}
export function selectedUser(state = {}, action) {
  switch (action.type) {
    case types.LOAD_SELECTED_USER_SUCCESS:
      return action.selectedUserData;
    default:
      return state;
  }
}

export function userGroups(state = [], action) {
  switch (action.type) {
    case types.LOAD_USERS_GROUPS_SUCCESS:
      return action.userGroups;
    default:
      return state;
  }
}

export function selectedUserGroup(state = [], action) {
  switch (action.type) {
    case types.LOAD_SELECTED_USER_GROUP_SUCCESS: {
      const arr =
        action.selectedUserGroup &&
        action.selectedUserGroup.content &&
        action.selectedUserGroup.content.map(item => ({ label: item }));
      return arr || [];
    }
    default:
      return state;
  }
}

export function shouldReloadOrganisationData(state = false, action) {
  switch (action.type) {
    case types.LOAD_ORGANISATIONS_SUCCESS:
      return false;
    case types.CREATE_ORGANISATION_SUCCESS:
    case types.SAVE_ORGANISATION_SUCCESS:
    case types.DELETE_ORGANISATION_SUCCESS:
    case types.ENABLE_ORGANISATION_SUCCESS:
    case types.DISABLE_ORGANISATION_SUCCESS:
      return true;
    default:
      return state;
  }
}
export function editedUserData(state = {}, action) {
  switch (action.type) {
    case types.SEND_EDITED_USER_DATA_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function addSingleUser(state = {}, action) {
  switch (action.type) {
    case types.ADD_SINGLE_USER_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function addMultipleUsers(state = [{}], action) {
  switch (action.type) {
    case types.ADD_MULTIPLE_USERS_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function usersFilter(state = {}, action) {
  switch (action.type) {
    case types.USERS_FILTER:
      return { field: action.field, value: action.value };
    case types.CLEAR_USERS_FILTER:
      return {}
    default:
      return state;
  }
}

export function usersSort(state = {}, action) {
  switch (action.type) {
    case types.USERS_SORT:
      return { field: action.field, revert: action.revert };
    default:
      return state;
  }
}

export function organisationFilter(state = '', action) {
  switch (action.type) {
    case types.ORGANISATION_FILTER:
      return action.organisationFilter;
    case types.CLEAR_ORGANISATION_FILTER: 
      return '';
    default:
      return state;
  }
}

export function multipleUsersParseResult(state = [], action) {
  switch (action.type) {
    case types.ADD_MULTIPLE_USERS_PARSE_RESULT:
      return action.result;
    case types.CLEAN_USERS_PARSE_RESULT:
      return [];
    default:
      return state;
  }
}

export function multipleUsersStep(state = 'add', action) {
  switch (action.type) {
    case types.SET_MULTIPLE_USERS_STEP:
      return action.createMultipleUsersStep;
    default:
      return state;
  }
}

export function auditLogs(state = [], action) {
  switch (action.type) {
    case types.LOAD_AUDIT_LOGS_SUCCESS: {
      action.data.map(auditLog => {
        const item = auditLog;
        item.startDate = new Date(
          item.startTime.substr(0, item.startTime.indexOf('#'))
        );
        return item;
      });
      return [...action.data];
    }
    case types.SORT_AUDIT_LOGS: {
      const currentState = state;
      if (action.direction === 'asc') {
        if (currentState[0].startDate > currentState.slice(-1)[0].startDate) {
          currentState.reverse();
        }
      } else if (
        currentState[0].startDate < currentState.slice(-1)[0].startDate
      ) {
        currentState.reverse();
      }
      return currentState;
    }
    default:
      return state;
  }
}

export function emailTemplatesList(state = [], action) {
  switch (action.type) {
    case types.LOAD_EMAIL_TEMPALTES_SUCCESS: {
      return [...action.data];
    }
    case types.SORT_EMAIL_TEMPLATES: {
      const currentState = state;
      if (action.direction === 'asc') {
        currentState.sort((a, b) => {
          if (a.templateName < b.templateName) {
            return -1;
          }
          if (a.templateName > b.templateName) {
            return 1;
          }
          return 0;
        });
      } else {
        currentState.sort((a, b) => {
          if (a.templateName > b.templateName) {
            return -1;
          }
          if (a.templateName < b.templateName) {
            return 1;
          }
          return 0;
        });
      }
      return currentState;
    }
    case types.EMAIL_TEMPLATES_FILTER: {
      const currentState = state;
      return currentState.filter(
        item => item.templateName.indexOf(action.text) >= 0
      );
    }
    default:
      return state;
  }
}

export function auditLogsFilter(state = '', action) {
  switch (action.type) {
    case types.AUDIT_LOGS_FILTER:
    case types.SET_AUDIT_FILTER_LOGS:
      return action.auditLogsFilter;
    default:
      return state;
  }
}

export function emailTemplatesFilter(state = '', action) {
  switch (action.type) {
    case types.SET_EMAIL_TEMPLATES_FILTER:
      return action.emailTemplatesFilter;
    default:
      return state;
  }
}

export function emailTemplate(state = {}, action) {
  switch (action.type) {
    case types.LOAD_EMAIL_TEMPALTE_SUCCESS: {
      const emailTemplateItem = action.data;
      let htmlPart = emailTemplateItem.htmlPart;
      let isBody = htmlPart.indexOf('<body>');
      if (isBody >= 0) {
        htmlPart = htmlPart.replace('<body>', '');
      }
      isBody = htmlPart.indexOf('</body>');
      if (isBody >= 0) {
        htmlPart = htmlPart.replace('</body>', '');
      }
      emailTemplateItem.htmlPart = htmlPart.trim();
      return emailTemplateItem;
    }
    default:
      return state;
  }
}

export function shouldReloadEmailTemplatesList(state = false, action) {
  switch (action.type) {
    case types.CREATE_EMAIL_TEMPLATE_SUCCESS:
      return true;
    case types.LOAD_EMAIL_TEMPALTES_SUCCESS:
      return false;
    default:
      return state;
  }
}

export function topOrganizations(state = {}, action) {
  switch (action.type) {
    case types.LOAD_TOP_ORGANIZATIONS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  organisations,
  parentOrganisations,
  usersList,
  editUser,
  selectedUser,
  shouldReloadOrganisationData,
  organisationFilter,
  editedUserData,
  userGroups,
  selectedUserGroup,
  shouldReloadUsersList,
  addSingleUser,
  addMultipleUsers,
  usersFilter,
  usersSort,
  closeBottombar,
  multipleUsersStep,
  multipleUsersParseResult,
  auditLogs,
  auditLogsFilter,
  emailTemplatesList,
  emailTemplatesFilter,
  emailTemplate,
  shouldReloadEmailTemplatesList,
  usersImportList,
  usersImportDetailsList,
  bulkImportResponse,
  topOrganizations
});
