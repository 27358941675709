import { combineReducers } from 'redux';

import { LOAD_INVESTMENT_PRODUCTS_SUCCESS, LOAD_PRODUCT_DETAIL_SUCCESS } from "components/pages/App/Overview/Cards/Content/Income/Investments/actions";
import { LOAD_INVESTMENTS_FORECAST_SUCCESS } from 'action-types/overview';


export function products(state = false, action) {

  switch (action.type) {
    case LOAD_INVESTMENT_PRODUCTS_SUCCESS:
      return [...action.data];
    default:
      return state;
  }
}

export function product(state = false, action) {

  switch (action.type) {
    case LOAD_PRODUCT_DETAIL_SUCCESS:
      return { ...action.data };
    default:
      return state;
  }
}

export function forecast(state = false, action) {
  switch (action.type) {
    case LOAD_INVESTMENTS_FORECAST_SUCCESS:
      return { ...action.data };
    default:
      return state;
  }
}

export default combineReducers({ products, product, forecast });