export default class CustomError extends Error {
  constructor(status = '500', ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    // Custom debugging information
    this.status = status;
    this.date = new Date();
  }
}
