export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CREATE_USER = 'CREATE_USER';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CLEAR_DATA_SUCCESS = 'CLEAR_DATA_SUCCESS';
export const RESET_DATA_SUCCESS = 'RESET_DATA_SUCCESS';
export const REFRESH_TOKENS_SUCCESS = 'REFRESH_TOKENS_SUCCESS';
export const GET_CREDENTIALS = 'GET_CREDENTIALS';

export const UPDATE_USER_LOCALE_SUCCESS = 'UPDATE_USER_LOCALE_SUCCESS';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const ENABLE_PDF_MENU_ITEM = 'ENABLE_PDF_MENU_ITEM';
export const DISABLE_PDF_MENU_ITEM = 'DISABLE_PDF_MENU_ITEM';
export const ENABLE_NIBUD_MENU_ITEM = 'ENABLE_NIBUD_MENU_ITEM';
export const DISABLE_NIBUD_MENU_ITEM = 'DISABLE_NIBUD_MENU_ITEM';
export const RESET_USER_MENU = 'RESET_USER_MENU';
//Costumer Journey flags
export const IS_IN_COSTUMER_JOURNEY = 'IS_IN_COSTUMER_JOURNEY';
export const LEFT_COSTUMER_JOURNEY = 'LEFT_COSTUMER_JOURNEY';

export const NIBUD_CONFIRMATION_CLICKED = 'NIBUD_CONFIRMATION_CLICKED';
export const NIBUD_IN_MODAL_FINISHED = 'NIBUD_IN_MODAL_FINISHED';

/** These have the purpose to persist the selected date even tho it's stored in child all related to save-data feature */
export const SAVE_DATA_POLICY_SUCCESS = 'SAVE_DATA_POLICY_SUCCESS';
export const USER_SUBMITTED_SAVE_DATA_OPTION_SUCCESS = 'USER_SUBMITTED_SAVE_DATA_OPTION_SUCCESS';
export const USER_RESET_SAVE_DATA_OPTION_SUCCESS = 'USER_RESET_SAVE_DATA_OPTION_SUCCESS';
/** In case on the RenderSaveDataRadio component the second radio text is clicked and no data is selected to populate the field */
export const SAVE_DATA_TEMP_DEADLINE_CACHE = 'SAVE_DATA_TEMP_DEADLINE_CACHE';
export const SAVE_DATA_TEMP_DEADLINE_RESET = 'SAVE_DATA_TEMP_DEADLINE_RESET';
