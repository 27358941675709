// @flow

import React from 'react';
import { toast } from 'react-toastify';
import store from '../../../../store';
import 'react-toastify/dist/ReactToastify.css';
import NotifContent from '../NotifContent';

const onClose = (type, descriptionKey) => {
  lastNotif[type].splice(lastNotif[type].indexOf(descriptionKey), 1);
};

const options_error = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  closeOnClick: true,
  autoClose: false,
  newestOnTop: true,
  className: 'fancy-toaster error',
  onClose: ({ descriptionKey }) => {
    onClose('error', descriptionKey);
  },
};

const options_success = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 8000,
  newestOnTop: true,
  className: 'fancy-toaster success',
  onClose: ({ descriptionKey }) => {
    onClose('success', descriptionKey);
  },
};

const options_warn = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 8000,
  newestOnTop: true,
  className: 'fancy-toaster warn',
  onClose: ({ descriptionKey }) => {
    onClose('warn', descriptionKey);
  },
};

const options_info = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 8000,
  newestOnTop: true,
  className: 'fancy-toaster info',
  onClose: ({ descriptionKey }) => {
    onClose('info', descriptionKey);
  },
};

const currentLang = key => {
  const intl = store.getState().intl.messages[key];
  if (typeof intl === 'undefined') {
    return key;
  }
  return intl;
};
const notif = {};
const lastNotif = {
  success: [],
  error: [],
  warn: [],
  info: [],
};

const notifContentComponent = (description: string, title: string) => {
  const content_description = {
    title: currentLang(title),
    description: currentLang(description),
    descriptionKey: description,
  };

  return <NotifContent {...content_description} />;
};

notif.success = (description: string, title = 'notif.success') => {
  const content = notifContentComponent(description, title);
  !lastNotif.success.includes(description) &&
    toast.success(content, options_success);
  lastNotif.success.push(description);
};

notif.error = (description: string, title = 'notif.error') => {
  const content = notifContentComponent(description, title);
  !lastNotif.error.includes(description) &&
    toast.error(content, options_error) &&
    lastNotif.error.push(description);
};

notif.warn = (description: string, title = 'notif.warn') => {
  const content = notifContentComponent(description, title);
  !lastNotif.warn.includes(description) && toast.warn(content, options_warn);
  lastNotif.warn.push(description);
};

notif.info = (description: string, title = 'notif.info') => {
  const content = notifContentComponent(description, title);
  !lastNotif.info.includes(description) && toast.info(content, options_info);
  lastNotif.info.push(description);
};

export { notif };
