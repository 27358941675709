import React from 'react';
import { connect } from 'react-redux';
import Button from '../../common/Button';
import Footer from '../../common/Footer';

import { apiHealthCheck } from '../../../services/api';
import './style.scss';

const retry = () => {
  //retry api status by reaload page for now
  window.location.reload(true);
};

class Maintenance extends React.Component {
  componentDidMount() {
    let healthyCount = 0;
    const intervalId = setInterval(() => {
      apiHealthCheck()
        .then(response => {
          if (response && response.message) {
            if (response.message.includes('success')) {
              healthyCount++;
              if (healthyCount > 2) {
                retry();
              }
            }
          }
        })
        .catch(e => {
          healthyCount = 0;
        });
    }, 10000);
    this.setState({
      intervalId,
    });
  }
  componentWillUnmount() {
    this.state.intervalId && clearInterval(this.state.intervalId);
  }
  render() {
    const currentLang = this.props.intl.messages;
    return (
      <div>
        <div className="app-container">
          <div className="navigation-container">
            <div className="navigation">
              <div className="navigation__logo"></div>
            </div>
          </div>
          <div className="page-container">
            <div className="maintenance-container">
              <div className="maintenance-logo"></div>
              <div className="maintenance-text">
                <div>{currentLang['maintenance.title']}</div>
                <div>{currentLang['maintenance.p1']}</div>
                <div>{currentLang['maintenance.p2']}</div>
                <Button label={currentLang['retry']} onClick={retry} />
              </div>
            </div>
          </div>
          <div className="footer-container footer--app">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  intl: state.intl,
});

export default connect(
  mapStateToProps,
  null
)(Maintenance);
