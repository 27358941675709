import React, { Suspense } from 'react';
import { checkBrowser } from 'services/utils';


const Root = React.lazy(() => import('./components/Root'));
const BrowserConfirmation = React.lazy(() =>
  import('./components/common/BrowserConfirmation')
);

const App = () => {
  const isSupportedBrowser = checkBrowser();
  return (
    <>
      {isSupportedBrowser ? (
        <Suspense fallback={<div />}>
          <Root />
        </Suspense>
      ) : (
          <Suspense fallback={<div />}>
            <BrowserConfirmation />
          </Suspense>
        )}
    </>
  );
}

export default App;