import * as types from 'action-types/common';
import { apiGet, apiPatch, apiPatchCustom } from 'services/api';
import { recursiveMapIdWithUuid } from 'services/utils';

export const selectAuthStep = createAuthStep => ({
  type: types.SET_AUTH_STEP,
  createAuthStep,
});

export const loadingSuccess = () => ({ type: types.LOADING, loading: false });

export function beginAjaxCall() {
  return { type: types.BEGIN_AJAX_CALL };
}

export function changepPasswordSuccess() {
  return { type: types.CHANGE_PASSWORD_SUCCESS };
}

export function forgotPasswordSuccess() {
  return { type: types.FORGOT_PASSWORD_SUCCESS };
}

export function ajaxCallError(data) {
  return { type: types.AJAX_CALL_ERROR, data };
}

export function setSelectedScenario(scenario) {
  return { type: types.SET_SELECTED_SCENARIO, scenario };
}

export function setCurrentYear(currentYear) {
  return { type: types.SET_CURRENT_YEAR, currentYear };
}

export function resetCurrentYear() {
  return { type: types.RESET_CURRENT_YEAR };
}

export function loadPersonsSuccess(data) {
  return {
    type: types.LOAD_PERSONS_SUCCESS,
    data: recursiveMapIdWithUuid(data),
  };
}

function generateTaxFileSuccess(data) {
  return {
    type: types.GENERATE_TAX_FILES,
    data,
  };
}

export function loadPersons(currentScenarioId, cb) {
  return dispatch =>
    apiGet(`scenarios/${currentScenarioId}/persons`).then(data => {
      dispatch(loadPersonsSuccess(data));
      if (cb) cb();
    });
}

export function resetNibudFlagSuccess(data) {
  return { type: types.RESET_NIBUD_FLAG_SUCCESS, data };
}

export function resetNibudFlag(currentScenarioId, scenario) {
  return dispatch => {
    dispatch(beginAjaxCall());
    apiPatch(`scenarios/${currentScenarioId}/reset/expense/flag`, scenario)
      .then(data => {
        dispatch(resetNibudFlagSuccess(data));
      })
      .catch(error => dispatch(ajaxCallError(error)));
  };
}

export const generateTaxFile = body => dispatch => {
  dispatch(beginAjaxCall());
  return apiPatchCustom('generator/file/tax', body, {
    Accept: 'application/xml',
  })
    .then(result => dispatch(generateTaxFileSuccess(result)))
    .catch(error => dispatch(ajaxCallError(error)));
};
