import axiosLib from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import Maintenance from '../../components/common/Maintenance';
import { Provider } from 'react-redux';
import store from '../../store';
import cognito from '../cognito';
import { API_URL, API_URL_FRESHDESK } from '../envs';
import CustomError from '../customError';
import { getStatusCodeFromError, sendNotifFromError } from './errors';

/**
 * Redirect to /logout
 */
const logoutUser = () => (window.location.href = '/logout');

/**
 * Axios global error handler. Parse error and send it to UI. In case of 403 status code, force user to logout
 * @param {Error} error
 */
const onError = (error) => {
  if (typeof error.response === 'undefined') {
    if (error.toString().includes('Error: Network Error')) {
      // api might be under maintenance or cannot respond
      ReactDOM.render(
        <Provider store={store}>
          <Maintenance />
        </Provider>,
        document.getElementById('app')
      );
    }
    return Promise.reject(error);
  }

  const status = getStatusCodeFromError(error);

  if (status === 403) {
    logoutUser();
  } else if (status === 503 || status === 504) {
    ReactDOM.render(
      <Provider store={store}>
        <Maintenance />
      </Provider>,
      document.getElementById('app')
    );
  }

  // Send notifications to UI
  sendNotifFromError(error);
  return Promise.reject(error);
};
let logOutTimer;
const axios = ({ ...args }) => {
  if (logOutTimer) clearTimeout(logOutTimer);
  logOutTimer = setTimeout(() => {
    logoutUser();
  }, 600000);
  return axiosLib(args);
};
const apiRequest = async (method, path, body) => {
  let cognitoTokens, accessToken;

  try {
    cognitoTokens = await cognito.getTokens().catch((e) => {
      // error receiving token, redirect to login
      if (e.name === 'NotAuthorizedException' || e.name === 'Error') {
        window.location.href = '/login'; //logout to clear the state, redirect to login
        throw e;
      }
    });
  } catch (error) {
    if (error instanceof CustomError) {
      return onError(error);
    } else {
      throw error;
    }
  }

  accessToken = cognitoTokens.access_token;

  return axios({
    method,
    data: body,
    url: `${API_URL}/${path}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((response) => response.data)
    .catch(onError);
};
// API connection
export const apiHealthCheck = (options) => {
  const req = axios.create({
    baseURL: `${API_URL}/health`,
    timeout: 9000,
  });
  return req(options)
    .then((response) => response.data)
    .catch((e) => e);
};
const publicApiRequest = (method, path, body) =>
  axios({
    method,
    data: body,
    url: `${API_URL}/${path}`,
  }).then(({ data }) => data);

const apiRequestCustom = async (method, path, body, headers) => {
  let cognitoTokens, accessToken;
  try {
    cognitoTokens = await cognito.getTokens();
  } catch (error) {
    if (error instanceof CustomError) {
      return onError(error);
    } else {
      throw error;
    }
  }

  accessToken = cognitoTokens.access_token;

  return axios({
    method,
    data: body,
    url: `${API_URL}/${path}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/pdf',
      ...headers,
    },
    responseType: 'blob',
  })
    .then((response) => response.data)
    .catch(onError);
};

const apiRequestBinaryInternal = async (options) => {
  let cognitoTokens, accessToken;
  try {
    cognitoTokens = await cognito.getTokens();
  } catch (error) {
    if (error instanceof CustomError) {
      return onError(error);
    } else {
      throw error;
    }
  }

  accessToken = cognitoTokens.access_token;

  return axios({
    ...options,
    url: `${API_URL}/${options.path}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: options.contentType,
    },
    responseType: 'arraybuffer',
  })
    .then((response) => {
      return new Blob([response.data], {
        type: options.contentType,
      });
    })
    .catch(onError);
};

const apiRequestJSON = async (method, path) => {
  let cognitoTokens, accessToken;
  try {
    cognitoTokens = await cognito.getTokens();
  } catch (error) {
    if (error instanceof CustomError) {
      return onError(error);
    } else {
      throw error;
    }
  }

  accessToken = cognitoTokens.access_token;

  return axios({
    method,
    data: {},
    url: `${API_URL}/${path}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data)
    .catch(onError);
};

const publicApiRequestFreshDesk = async (method, path, data) => {
  let apiKey = btoa('xlRq6SlN3GaWA6Wj187');
  return axios({
    method,
    data: data,
    url: `${API_URL_FRESHDESK}/${path}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + apiKey,
    },
  })
    .then((response) => response.data)
    .catch(onError);
};

export default {
  getCurrentUser() {
    return apiRequest('GET', 'users');
  },
  loadScenarios() {
    return apiRequest('GET', 'scenarios');
  },
  loadAowItems(scenarioId, currentYear) {
    return apiRequest(
      'GET',
      `scenarios/${scenarioId}/aow/${currentYear}`,
      null,
      scenarioId
    );
  },
  errorHandle(error) {
    return onError(error);
  },
};

export const publicApiGet = (path) => publicApiRequest('GET', path);
export const publicApiPatch = (path, body) =>
  publicApiRequest('PATCH', path, body);
export const publicApiPost = (path, body) =>
  publicApiRequest('POST', path, body);
export const publicApiPostFreshDesk = (path, body) =>
  publicApiRequestFreshDesk('POST', path, body);
export const apiGet = (path, body, scenarioId) =>
  apiRequest('GET', path, body, scenarioId);
export const apiGetCustom = (path, body, scenarioId) =>
  apiRequestCustom('GET', path, body, scenarioId);
export const apiPatchCustom = (path, body, headers) =>
  apiRequestCustom('PATCH', path, body, headers);
export const apiGetWithJSON = (path, body, scenarioId) =>
  apiRequestJSON('GET', path, body, scenarioId);
export const apiPatch = (path, body) => apiRequest('PATCH', path, body);
export const apiPost = (path, body) => apiRequest('POST', path, body);
export const apiDelete = (path, body) => apiRequest('DELETE', path, body);
export const apiRequestBinary = (method, options) =>
  apiRequestBinaryInternal(method, options);
