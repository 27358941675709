import React from 'react';

const NotifContent = (props) => {
    return (
        <div>
          <div className="title">{props.title}</div>
          <div className="description">{props.description}</div>
        </div>
    );
}

export default NotifContent;
