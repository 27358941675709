import * as types from 'action-types/user';
import { combineReducers } from 'redux';
import { setLocalStorageData } from 'services/utils';

export function signIn(state = false, action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS:
      return action.userData;
    default:
      return state;
  }
}

export function signUp(state = false, action) {
  switch (action.type) {
    case types.CREATE_USER:
      return true;
    default:
      return state;
  }
}

export function currentUser(state = null, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return action.userData;
    case types.LOGOUT:
      localStorage.removeItem('currentYear');
      return null;
    default:
      return state;
  }
}

export function showNewPassword(state = false, action) {
  switch (action.type) {
    case 'SHOW_NEW_PASSWORD':
      return action.showNewPassword;
    default:
      return state;
  }
}

export function showPasswordSuccess(state = false, action) {
  switch (action.type) {
    case 'SHOW_PASSWORD_SUCCESS':
      return action.showPasswordSuccess;
    default:
      return state;
  }
}

export function userHasResetRole(state = false, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS: {
      const userGroup = action.userData.user_group || [];
      return (
        userGroup.find(
          item => item === 'ROLE_POWER_USER' || item === 'ROLE_ADMIN'
        ) != null
      );
    }
    default:
      return state;
  }
}

export function userHasSaveDataFeature(state = false, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS: {
      const userGroup = action.userData.user_group || [];
      return userGroup.find(item => item === 'ROLE_SAVE_DATA') != null;
    }
    case types.REFRESH_TOKENS_SUCCESS: {
      const userGroup = action.data.idToken.payload['cognito:groups'] || [];
      return userGroup.find(item => item === 'ROLE_SAVE_DATA') != null;
    }
    default:
      return state;
  }
}

export function userHasBasicRole(state = false, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS: {
      const userGroup = action.userData.user_group || [];
      return userGroup.find(item => item === 'ROLE_BASIC') != null;
    }
    case types.REFRESH_TOKENS_SUCCESS: {
      const userGroup = action.data.idToken.payload['cognito:groups'] || [];
      return userGroup.find(item => item === 'ROLE_BASIC') != null;
    }
    default:
      return state;
  }
}

export function isLoggedIn(state = false, action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS:
    case types.GET_USER_SUCCESS:
      return !!action.userData;
    case types.LOGOUT:
      return false;
    default:
      return state;
  }
}

export function isAdmin(state = false, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS: {
      const userGroup = action.userData.user_group || [];
      return userGroup.find(item => item === 'ROLE_ADMIN') != null;
    }
    default:
      return state;
  }
}

export function canGenerateTaxFiles(state = false, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS: {
      const userGroup = action.userData.user_group || [];
      return userGroup.find(item => item === 'ROLE_GENERATE_TAX_FILES') != null;
    }
    default:
      return state;
  }
}

export function userLocale(state = 'nl', action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return action.userData.locale;
    default:
      return state;
  }
}

export function shouldUpdateUser(state = { value: false }, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return {
        value: false,
      };
    case types.UPDATE_USER_LOCALE_SUCCESS:
      return {
        value: true,
        time: new Date().getTime(),
      };
    default:
      return state;
  }
}

export function userCredentials(state = false, action) {
  switch (action.type) {
    case 'GET_CREDENTIALS':
      return action.data;
    default:
      return state;
  }
}

const defaultMenuState = {
  showDownloadPDFItem: false,
  showUpdateNibudExpensesItem: false,
};

export function userMenu(state = defaultMenuState, action) {
  switch (action.type) {
    case types.RESET_USER_MENU:
      return defaultMenuState;
    case types.ENABLE_PDF_MENU_ITEM:
      return {
        ...state,
        showDownloadPDFItem: action.value,
      };
    case types.DISABLE_PDF_MENU_ITEM:
      return {
        ...state,
        showDownloadPDFItem: action.value,
      };
    case types.ENABLE_NIBUD_MENU_ITEM:
      return {
        ...state,
        showUpdateNibudExpensesItem: action.value,
      };
    case types.DISABLE_NIBUD_MENU_ITEM:
      return {
        ...state,
        showUpdateNibudExpensesItem: action.value,
      };
    default:
      return state;
  }
}

export function userFinishedSaveDataPolicyStep(state = false, action) {
  switch (action.type) {
    case types.USER_SUBMITTED_SAVE_DATA_OPTION_SUCCESS:
      setLocalStorageData('submittedSaveData', action.value);
      return action.value;
    case types.USER_RESET_SAVE_DATA_OPTION_SUCCESS:
      setLocalStorageData('submittedSaveData', action.value);
      return action.value;
    default:
      return state;
  }
}

export function userIsInCustomerJourney(state = false, action) {
  switch (action.type) {
    case types.IS_IN_COSTUMER_JOURNEY:
      return true;
    case types.LEFT_COSTUMER_JOURNEY:
      return false;
    default:
      return state;
  }
}

export function NIBUDResetPromptIsClicked(state = false, action) {
  switch (action.type) {
    case types.NIBUD_CONFIRMATION_CLICKED:
      return true;
    case types.NIBUD_IN_MODAL_FINISHED:
      return false;
    default:
      return state;
  }
}

export function saveDataDeadlineCache(state = null, action) {
  switch (action.type) {
    case types.SAVE_DATA_TEMP_DEADLINE_CACHE:
      return action.value;
    case types.SAVE_DATA_TEMP_DEADLINE_RESET:
      return action.value;
    default:
      return state;
  }
}

export default combineReducers({
  signIn,
  signUp,
  currentUser,
  userHasResetRole,
  showNewPassword,
  userHasBasicRole,
  showPasswordSuccess,
  isLoggedIn,
  isAdmin,
  canGenerateTaxFiles,
  userLocale,
  shouldUpdateUser,
  userCredentials,
  userMenu,
  userIsInCustomerJourney,
  NIBUDResetPromptIsClicked,
  userHasSaveDataFeature,
  userFinishedSaveDataPolicyStep,
  saveDataDeadlineCache,
});
