// @flow
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

type PropsType = {
  /** Primary button true|false */
  primary: bool,
  buttonClassName: string,
  type: string,
  onClick: () => void,
};

/**
 * 
 * Button
 */
const Button = ({
  buttonClassName,
  type,
  primary,
  small,
  block,
  secondary,
  special,
  blue,
  onClick,
  id,
  disabled,
  testSelector,
  label,
  children,
}: PropsType) => {

  const getClassNames = () => {
    return classNames('btn', {
      [buttonClassName]: buttonClassName,
      'btn--primary': primary,
      'btn--small': small,
      'btn--block': block,
      'btn--secondary': secondary,
      'btn--special': special,
      'btn--blue': blue,
    });
  };
  return (
    <button
      type={type}
      className={getClassNames()}
      onClick={onClick}
      id={id}
      disabled={disabled}
      ta-id={testSelector}
    >
      {label}
      {children}
    </button>
  );
};

Button.defaultProps = {
  primary: false,
  small: false,
  block: false,
  buttonClassName: null,
  type: 'button',
  children: null,
  secondary: false,
  special: false,
  disabled: false,
  blue: false,
};

Button.propTypes = {
  /** Handler for onClick event */
  onClick: PropTypes.func,
  /** Button label */
  label: PropTypes.string.isRequired,
  /** If true, Button style is primary */
  primary: PropTypes.bool,
  /** Makes a button small */
  small: PropTypes.bool,
  block: PropTypes.bool,
  secondary: PropTypes.bool,
  special: PropTypes.bool,
  /** Additional class name that overwrites the current class in the component*/
  buttonClassName: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.object,
  /** Disable button */
  disabled: PropTypes.bool,
  blue: PropTypes.bool,
  /** Translates in ta-id for automated tests  */
  testSelector: PropTypes.string
};

export default Button;


