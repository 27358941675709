import * as types from 'action-types/language';
import nlMessages from '../../i18n/nl.json';
import enMessages from '../../i18n/en.json';

export default function localesReducer(state = { en: enMessages, nl: nlMessages }, action) {
  switch (action.type) {
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        ...action.messages,
      };
    default:
      return state;
  }
}
