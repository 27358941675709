import * as types from 'action-types/customerJourney';
import * as commonTypes from 'action-types/common';
import { combineReducers } from 'redux';

export function nibudCategories(state = [], action) {
  switch (action.type) {
    case types.GET_NIBUD_CATEGORIES_SUCCESS: {
      const categories = action.categories.map((nibudCategory) => ({
        ...nibudCategory,
        items: nibudCategory.items.map((item) => ({
          ...item,
          value: item.example,
        })),
        label: `nibud.all.${nibudCategory.expenseSection}`,
        open: false,
        nibud: true,
        totalNibud: nibudCategory.nibudReferenceValue,
        totalAmount: nibudCategory.nibudReferenceValue,
      }));
      return categories;
    }
    default:
      return state;
  }
}

export function userData(state = {}, action) {
  switch (action.type) {
    case types.GET_API_USER_SUCCESS:
      return action.userData;
    default:
      return state;
  }
}

export function scenarioId(state = 0, action) {
  switch (action.type) {
    case types.GET_SCENARIO_ID_SUCCESS:
      localStorage.setItem('scenarioId', action.scenarioId);
      return action.scenarioId;
    case commonTypes.SET_SELECTED_SCENARIO:
      if (action.scenario != null) {
        localStorage.setItem('scenarioId', action.scenario.id);
        localStorage.removeItem('currentYear');
      }
      return action.scenario.id;
    default:
      return state;
  }
}

export function personsData(state = [], action) {
  switch (action.type) {
    case types.GET_PERSONS_SUCCESS:
      action.personsData.map((person) => {
        const item = person;
        item.birthDate = new Date(
          new Date(item.birthDate).getTime() -
            new Date(item.birthDate).getTimezoneOffset() * 60000
        ).toISOString(true);
        item.label = person.fullName;
        item.value = person.id;
        item.key = person.id;
        return item;
      });
      return [...action.personsData];
    default:
      return state;
  }
}

export function cars(state = [], action) {
  switch (action.type) {
    case types.GET_CARS_SUCCESS:
      return action.cars;
    default:
      return state;
  }
}

export function children(state = [], action) {
  switch (action.type) {
    case types.GET_CHILDREN_SUCCESS:
      action.children.map((child) => {
        const item = child;
        item.birthDate = new Date(
          new Date(item.birthDate).getTime() -
            new Date(item.birthDate).getTimezoneOffset() * 60000
        ).toISOString(true);
        return item;
      });
      return [...action.children];
    default:
      return state;
  }
}

export function income(state = {}, action) {
  switch (action.type) {
    case types.GET_INCOME_SUCCESS:
      return action.income;
    default:
      return state;
  }
}

export function incomeTypes(state = [], action) {
  switch (action.type) {
    case types.GET_INCOME_TYPES_SUCCESS:
      return action.incomeTypes;
    default:
      return state;
  }
}
export function shouldUpdateCustomerJourney(state = false, action) {
  switch (action.type) {
    case types.UPLOAD_TAX_SUCCESS:
      return true;
    default:
      return state;
  }
}

export default combineReducers({
  nibudCategories,
  userData,
  scenarioId,
  personsData,
  cars,
  children,
  income,
  incomeTypes,
});
