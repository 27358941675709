import { beginAjaxCall } from "actions/common";

export const LOAD_INVESTMENT_PRODUCTS_SUCCESS = 'LOAD_INVESTMENT_PRODUCTS_SUCCESS';
export const LOAD_PRODUCT_DETAIL_SUCCESS = 'LOAD_PRODUCT_DETAIL_SUCCESS';


export const loadInvestmentProductsSuccess = data => ({
    type: LOAD_INVESTMENT_PRODUCTS_SUCCESS,
    data,
});

export const loadProductDetailSuccess = data => ({
    type: LOAD_PRODUCT_DETAIL_SUCCESS,
    data,
});

export const loadInvestmentProducts = () => dispatch => {
    dispatch(beginAjaxCall());
    const products = [{
        "id": "1",
        "name": "Klass Boer",
        "company": "Robeco",
        "product": "Beheerd Beleggen",
        "startDate": "2013-10-01",
        "amountMonth": 150,
        "accumulatedCapital": 10000
    }, {
        "id": "2",
        "name": "Klass Boer",
        "company": "Nationale Nederlanden",
        "product": "Pensioenpodje Portofolio division",
        "startDate": "2021-10-01",
        "amountMonth": 100,
        "accumulatedCapital": 5000
    }];
    dispatch(loadInvestmentProductsSuccess(products));
};

export const loadProductDetails = (id) => dispatch => {
    dispatch(beginAjaxCall());
    const products = [{
        id: 1,
        name: "Beheerd Beleggen",
        company: "Robeco",
        valueOfInvestments: 10000,
        amountMonth: 150,
        bondsEquities: [
            { name: 'Bonds', value: 85 },
            { name: 'Equities', value: 15 },
        ],
        products: [

            {
                "name": "IT Innovations",
                "size": 30
            },
            {
                "name": "Energy",
                "size": 40
            },
            {
                "name": "Real estate",
                "size": 30
            }
        ],
    },
    {
        id: 2,
        name: "Pensioenpodje Portofolio division",
        company: "Nationale Nederlanden",
        valueOfInvestments: 5000,
        amountMonth: 100,
        bondsEquities: [
            { name: 'Bonds', value: 85 },
            { name: 'Equities', value: 15 },
        ],
        products: [

            {
                "name": "Retail",
                "size": 20
            },
            {
                "name": "Startups",
                "size": 40
            },
            {
                "name": "Real estate",
                "size": 40
            }
        ]
    },
    ];
    const product = products[id - 1];

    dispatch(loadProductDetailSuccess(product));
};