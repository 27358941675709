import moment from 'moment';
const { detect } = require('detect-browser');

export const isEmptyObject = testedItem => {
  const isEmpty = testedItem
    ? Object.entries(testedItem).length === 0 &&
      testedItem.constructor === Object
    : false;

  return isEmpty;
};

export const isIterableObject = obj => {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

export const getFormattedDate = (date, locale) => {
  const m = moment(date).format('MM/YYYY');
  return m;
};

export const getFormattedDateFull = (date, locale) => {
  const m = moment(date).format('DD/MM/YYYY');
  return m;
};

export const isNumeric = value => {
  let returnValue =
    value.toString().includes(',') ||
    (!isNaN(parseFloat(value)) && isFinite(parseFloat(value)));
  return returnValue;
};

export const isNumericWithTwoDecimals = value => {
  const twoDecimalsComma = /^\d+,\d{0,2}$/;
  //Nasty fix because NumberFormat keeps returning formatted with dot
  const twoDecimalsDot = /^\d+\.\d{0,2}$/;
  const regularInteger = /^\d+$/;

  return (
    regularInteger.test(value) ||
    twoDecimalsComma.test(value) ||
    twoDecimalsDot.test(value)
  );
};

/**
 * Determines weather a variable is or can be of type number
 * detects floats and integers as strings or float/int
 *
 * @param {mixed} amount
 */
export const isOfTypeNumber = amount => {
  const numericPattern = /^[1-9]\d{0,2}(\.?\d{3})*(,\d+)?$/;
  let isValid = false;
  if (
    amount &&
    amount !== '' &&
    (numericPattern.test(amount) || !isNaN(amount))
  ) {
    isValid = true;
  }

  return isValid;
};

export const checkBrowser = () => {
  const browser = detect();
  switch (browser && browser.name) {
    case 'chrome':
      return browser.version.split('.')[0] >= 60;
    case 'firefox':
      return browser.version.split('.')[0] >= 55;
    case 'edge':
      return browser.version.split('.')[0] >= 16;
    case 'edge-chromium':
        return browser.version.split('.')[0] >= 16;
    case 'safari':
      return browser.version.split('.')[0] >= 9;
    case 'ios':
      return browser.version.split('.')[0] >= 9;
    case 'ie':
      return browser.version.split('.')[0] >= 11;
    default:
      //check mobile browsers by os
      if (browser && browser.os) {
        if (browser.os.toLowerCase().includes('ios')) {
          return browser.version.split('.')[0] >= 9;
        } else if (browser.os.toLowerCase().includes('android')) {
          return browser.version.split('.')[0] >= 8;
        }
      }
      return false;
  }
};

export const normalizeNr = value => {
  let newValue;
  newValue = value.split('.').join('');
  return newValue;
};

export const parseNumberField = (value) => {
  if (typeof value !== 'number') {
    let valueNumber;

    valueNumber = value.split('.').join('');
    if (value.includes(',')) {
      valueNumber = value.replace(',', '.');
    }
    return parseFloat(valueNumber);
  }
  return value;
};

/**
 * Rounds float to integer
 *
 * @param {mixed} value
 */
export const roundNumber = value => {
  if (typeof value !== 'boolean' && isOfTypeNumber(value)) {
    return Math.round(parseFloat(value), 10);
  }

  return 0;
};

export const formatNumber = unformattedNumber => {
  // we're using nl-NL because we need a specific number format, unchangeable in other languages
  if (
    parseInt(unformattedNumber) === 0 ||
    parseFloat(unformattedNumber) === 0.0
  ) {
    return 0.0;
  }

  if (
    !unformattedNumber ||
    isEmptyObject(unformattedNumber) ||
    unformattedNumber === null
  ) {
    return null;
  } else {
    return unformattedNumber.toLocaleString('nl-NL');
  }
};

/**
 * Parses the result of usersBulkImport
 * @param {array} invalidParams
 * @returns {array}
 */
export const sortAndParseResult = invalidParams => {
  let sorted_results = invalidParams.sort((a, b) => {
    //Regex to extract digit from string
    const a_as_digit = Number(a.name.match(/\d+/)[0]);
    const b_as_digit = Number(b.name.match(/\d+/)[0]);

    if (a_as_digit > b_as_digit) {
      return 1;
    }

    return -1;
  });

  let parsed_results = [];
  let iterator = 0;
  let messagesObject = {};

  for (let i = 0; i < sorted_results.length; i++) {
    const currentEntry = sorted_results[i];

    if (currentEntry.name.includes(`users[${iterator}]`) === false) {
      iterator++;
      messagesObject = {};
    }

    const propertyName = currentEntry.name.replace(`users[${iterator}].`, '');
    messagesObject[propertyName] = capitalizeFirstLetter(currentEntry.message);
    messagesObject = Object.assign(messagesObject, parsed_results[iterator]);
    parsed_results[iterator] = messagesObject;
  }

  return parsed_results;
};

/**
 *Capitalizes the error message
 * @param {string} message
 * @returns {string}
 */
export function capitalizeFirstLetter(message) {
  if (typeof message === 'string' || message instanceof String) {
    return message.charAt(0).toUpperCase() + message.slice(1);
  }
  return '';
}

/**
 * Returns a string that represents a class setting the color of the dot
 * in the import and import-details screens
 * @param {string} status
 */
export function getClassForStatus(status = 'PROCESSING') {
  if (typeof status === 'string' || status instanceof String) {
    const dictionary = {
      PROCESSING: 'dot--processing',
      FAILED: 'dot--failed',
      FINISH: 'dot--finish',
      WARNING: 'dot--warning',
      SUCCESS: 'dot--success',
    };

    if (dictionary[status.toUpperCase()]) {
      return dictionary[status.toUpperCase()];
    }
  }

  return '';
}

/**
 * Returns radio value for save data policy of users from RHK
 * or that have SAVE_DATA_ROLE in cognito
 *
 * @param {string|null} value
 */
export function mapSaveDataPolicy(value = null) {
  let returnValue = 'yes'; // data given as null means it will save them until reset

  if (typeof value === 'string' && moment(new Date(value)).isValid()) {
    const today = moment(new Date());
    const apiDate = moment(new Date(value));

    const isInFuture = apiDate.isAfter(today, 'days');
    const isToday = apiDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');

    if (isInFuture) {
      returnValue = 'until';
    } else if (isToday) {
      returnValue = 'no';
    }
  }

  return returnValue;
}

/**
 * Used for generating unique id's
 */
export function getRandomInt(min, max) {
  if (isOfTypeNumber(min) && isOfTypeNumber(max)) {
    const min_limit = Math.ceil(min);
    const max_limit = Math.floor(max);
    return Math.floor(Math.random() * (max_limit - min_limit + 1)) + min_limit;
  }

  return 0;
}

/**
 * Transforms the cognito status into user readable string
 *
 * @param {String} status
 * @param {Array} translation
 */
export function parseCognitoStatus(status = null, translation = null) {
  if (status && translation) {
    switch (status) {
      case 'CONFIRMED':
        return translation['admin.cognito-status-yes'];
      case 'FORCE_CHANGE_PASSWORD':
        return translation['admin.cognito-status-no'];
      default:
        return 'Pending';
    }
  }

  return 'Pending';
}

/**
 * Maps the .id used in the app with
 * a new unique identifier namely uuid
 * reasons: pen-test
 *
 * @param {Object} currentObject
 * @returns {Object}
 */
export function mapIdWithUuid(currentObject = {}) {
  if (
    currentObject &&
    typeof currentObject === 'object' &&
    !isEmptyObject(currentObject) &&
    currentObject.hasOwnProperty('uuid')
  ) {
    currentObject.id = currentObject.uuid;
  }

  return currentObject;
}

/**
 * Map an array's uuid to the id property
 * @param {Array} arrResponse
 */
export function arrayMapIdWithUuid(arrResponse = []) {
  if (Array.isArray(arrResponse)) {
    for (let item in arrResponse) {
      mapIdWithUuid(arrResponse[item]);
    }
  }

  return arrResponse;
}

/**
 * Response from back-end and all the id's will be populated
 * recursively using the value from uuid field
 * @param {Array|Object} response
 * @returns {Array|Object}
 */
export function recursiveMapIdWithUuid(response = null) {
  if (Array.isArray(response)) {
    arrayMapIdWithUuid(response);
  }

  if (typeof response === 'object') {
    mapIdWithUuid(response);
    for (let key in response) {
      if (Array.isArray(response[`${key}`])) {
        arrayMapIdWithUuid(response[`${key}`]);
      }
      recursiveMapIdWithUuid(response[`${key}`]);
    }
  }

  return response;
}

/**
 * Fetches keys from local storage and executes
 * JSON.parse on them in order to respect data types
 *
 * @param {String} response
 * @param {Mixed|null} key
 */
export function fetchLocalStorageData(key) {
  const isString =
    (typeof key === 'string' || key instanceof String) && key !== '';

  if (isString && localStorage.getItem(key) !== null) {
    return JSON.parse(localStorage.getItem(key));
  }

  return null;
}

/**
 * Sets item in local-storage and judging by success
 * returns true or false accordingly
 *
 * @param {String} key
 */
export function setLocalStorageData(key, value) {
  const isString =
    (typeof key === 'string' || key instanceof String) && key !== '';

  if (isString) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
