// @flow
import { combineReducers } from 'redux';
import * as types from 'action-types/common';
import * as overviewTypes from 'action-types/overview';
import * as config from 'utils/config';

export function authStep(state = 'login', action) {
  switch (action.type) {
    case types.SET_AUTH_STEP:
      return action.createAuthStep;
    default:
      return state;
  }
}

export function loading(state = true, action) {
  switch (action.type) {
    case types.LOADING:
      return action.loading;
    case types.AJAX_CALL_ERROR:
      return false;
    default:
      return state;
  }
}

export function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

export function ajaxStatusReducer(state = 0, action) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  } else if (actionTypeEndsInSuccess(action.type)) {
    return state > 0 ? state - 1 : 0;
  } else if (action.type === types.AJAX_CALL_ERROR) {
    return 0;
  }
  return state;
}

export function selectedScenario(state = {}, action) {
  switch (action.type) {
    case types.SET_SELECTED_SCENARIO:
      if (action.scenario != null) {
        localStorage.setItem('scenarioId', action.scenario.id);
        localStorage.removeItem('currentYear');
      }
      return action.scenario;
    case overviewTypes.LOAD_SCENARIOS_SUCCESS: {
      const currentScenario = localStorage.getItem('scenarioId');
      let activeScenario = action.data.filter(
        (scenario) => scenario.id === currentScenario
      )[0];
      if (!activeScenario) {
        activeScenario = action.data.filter(
          (scenario) => scenario.code === config.default.defaultScenario
        )[0];
      }
      if (activeScenario != null) {
        localStorage.setItem('scenarioId', activeScenario.id);
      }
      return activeScenario;
    }
    default:
      return state;
  }
}

export function shouldUpdateNibud(state = false, action) {
  switch (action.type) {
    case types.SET_SELECTED_SCENARIO:
      return !action.scenario.flagNibudPopUp;
    case overviewTypes.LOAD_SCENARIOS_SUCCESS: {
      const currentScenario = localStorage.getItem('scenarioId');
      let activeScenario = action.data.filter(
        (scenario) => scenario.id === currentScenario
      )[0];
      if (!activeScenario) {
        activeScenario = action.data.filter(
          (scenario) => scenario.code === config.default.defaultScenario
        )[0];
      }
      return !activeScenario.flagNibudPopUp;
    }
    case types.RESET_NIBUD_FLAG_SUCCESS:
      return !action.data.flagNibudPopUp;
    default:
      return state;
  }
}

export function persons(state = [], action) {
  switch (action.type) {
    case types.LOAD_PERSONS_SUCCESS:
      action.data.map((person) => {
        const item = person;
        item.birthDate = new Date(
          new Date(item.birthDate).getTime() -
            new Date(item.birthDate).getTimezoneOffset() * 60000
        ).toISOString(true);
        item.label = person.fullName;
        item.value = person.id;
        item.key = person.id;
        return item;
      });
      return [...action.data];
    default:
      return state;
  }
}

export default combineReducers({
  authStep,
  loading,
  ajaxCallsInProgress: ajaxStatusReducer,
  persons,
  shouldUpdateNibud,
  selectedScenario,
});
