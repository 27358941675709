export const getEnvVar = (name: string, defaultVal: string = '') =>
  process.env[`REACT_APP_${name}`] || defaultVal;


export const API_URL = getEnvVar('API_URL', '');
export const API_URL_FRESHDESK = getEnvVar('API_URL_FRESHDESK', 'https://pensioenpod.freshdesk.com/api');
export const API_URL_CAPTCHA = getEnvVar('API_URL_CAPTCHA', '6LdYCvAUAAAAAEZL9W9mltxbo_7FZi7rUdwJyG_j');
export const SENTRY_DSN_URL = getEnvVar('SENTRY_DSN_URL', 'https://1451ad28eeaf485ab290ed606db11ac4@sentry.io/1259933');
export const ENVIRONMENT = getEnvVar('ENVIRONMENT', '');
export const FEATURE_FETCH_URM_ENABLED = getEnvVar('FEATURE_FETCH_URM_ENABLED', false);
