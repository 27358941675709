export const LOAD_SCENARIOS_SUCCESS = 'LOAD_SCENARIOS_SUCCESS';
export const SELECT_SCENARIO = 'SELECT_SCENARIO';
export const SET_SELECTED_SCENARIO = 'SET_SELECTED_SCENARIO';
export const SET_RETIREMENT_TYPE = 'SET_RETIREMENT_TYPE';
export const DISABLE_RETIREMENT_ACCESS = 'DISABLE_RETIREMENT_ACCESS';
export const SET_RETIREMENT_AGE = 'SET_RETIREMENT_AGE';
export const LOAD_RETIREMENT_AGES_SUCCESS = 'LOAD_RETIREMENT_AGES_SUCCESS';
export const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR';
export const SAVE_SCENARIO_SUCCESS = 'SAVE_SCENARIO_SUCCESS';
export const SET_HIGH_LOW = 'SET_HIGH_LOW';
export const RESET_NIBUD_FLAG_SUCCESS = 'RESET_NIBUD_FLAG_SUCCESS';

export const LOAD_PERSONS_SUCCESS = 'LOAD_PERSONS_SUCCESS';

export const AOW_LOADED_SUCCESS = 'AOW_LOADED_SUCCESS';

export const PENSION_LOADED_SUCCESS = 'PENSION_LOADED_SUCCESS';
export const PENSION_TYPES_LOADED_SUCCESS = 'PENSION_TYPES_LOADED_SUCCESS';
export const SAVE_PENSION_SUCCESS = 'SAVE_PENSION_SUCCESS';
export const CALCULATE_PRE_PENSION_SUCCESS = 'CALCULATE_PRE_PENSION_SUCCESS';

export const LIFE_INSURANCE_LOADED_SUCCESS = 'LIFE_INSURANCE_LOADED_SUCCESS';
export const LIFE_INSURANCE_TYPES_LOADED_SUCCESS = 'LIFE_INSURANCE_TYPES_LOADED_SUCCESS';
export const LIFE_INSURANCE_TAX_TYPES_LOADED_SUCCESS = 'LIFE_INSURANCE_TAX_TYPES_LOADED_SUCCESS';
export const SAVE_LIFE_INSURANCE_SUCCESS = 'SAVE_LIFE_INSURANCE_SUCCESS';

export const INCOME_LOADED_SUCCESS = 'INCOME_LOADED_SUCCESS';
export const INCOME_TYPES_LOADED_SUCCESS = 'INCOME_TYPES_LOADED_SUCCESS';
export const SAVE_INCOME_SUCCESS = 'SAVE_INCOME_SUCCESS';

export const SAVINGS_LOADED_SUCCESS = 'SAVINGS_LOADED_SUCCESS';
export const CALCULATE_SAVINGS_SUCCESS = 'CALCULATE_SAVINGS_SUCCESS';
export const SAVE_SAVINGS_SUCCESS = 'SAVE_SAVINGS_SUCCESS';

export const INVESTMENTS_LOADED_SUCCESS = 'INVESTMENTS_LOADED_SUCCESS';
export const SAVE_INVESTMENTS_SUCCESS = 'SAVE_INVESTMENTS_SUCCESS';
export const CALCULATE_INVESTMENTS_SUCCESS = 'CALCULATE_INVESTMENTS_SUCCESS';

export const HOUSE_LOADED_SUCCESS = 'HOUSE_LOADED_SUCCESS';
export const SAVE_HOUSE_SUCCESS = 'SAVE_HOUSE_SUCCESS';
export const CALCULATE_HOUSE_SUCCESS = 'CALCULATE_HOUSE_SUCCESS';

export const INHERITANCE_LOADED_SUCCESS = 'INHERITANCE_LOADED_SUCCESS';
export const INHERITANCE_TYPES_LOADED_SUCCESS = 'INHERITANCE_TYPES_LOADED_SUCCESS';
export const SAVE_INHERITANCE_SUCCESS = 'SAVE_INHERITANCE_SUCCESS';
export const CALCULATE_INHERITANCE_SUCCESS = 'CALCULATE_INHERITANCE_SUCCESS';

export const MORTGAGE_LOADED_SUCCESS = 'MORTGAGE_LOADED_SUCCESS';
export const MORTGAGE_TYPES_LOADED_SUCCESS = 'MORTGAGE_TYPES_LOADED_SUCCESS';
export const MORTGAGE_SAVE_SUCCESS = 'MORTGAGE_SAVE_SUCCESS';
export const RENT_LOADED_SUCCESS = 'RENT_LOADED_SUCCESS';
export const RENT_SAVE_SUCCESS = 'RENT_SAVE_SUCCESS';

export const LOANS_LOADED_SUCCESS = 'LOANS_LOADED_SUCCESS';
export const LOAN_TYPES_LOADED_SUCCESS = 'LOAN_TYPES_LOADED_SUCCESS';
export const LOANS_SAVE_SUCCESS = 'LOANS_SAVE_SUCCESS';

export const NIBUD_LOADED_SUCCESS = 'NIBUD_LOADED_SUCCESS';
export const NIBUD_SAVE_SUCCESS = 'NIBUD_SAVE_SUCCESS';
