import * as dashboardTypes from 'action-types/dashboard';
import * as userTypes from 'action-types/user';
import * as ovTypes from 'action-types/overview';
import * as cjTypes from 'action-types/customerJourney';
import * as types from 'utils/action-types';
import { combineReducers } from 'redux';
import { updateIncomeInvestments } from 'reducers/overview';

export function userData(state = {}, action) {
  switch (action.type) {
    case dashboardTypes.LOAD_API_USER_SUCCESS:
      return action.userData;
    default:
      return state;
  }
}

export function reminderNotificationEmail(state = true, action) {
  switch (action.type) {
    case cjTypes.GET_API_USER_SUCCESS:
      return action.userData.reminderNotificationEmail || true;
    default:
      return state;
  }
}

export function years(state: Array = [], action) {
  switch (action.type) {
    case dashboardTypes.LOAD_YEARLY_OVERVIEW_SUCCESS:
      return [...action.data];
    case ovTypes.URM_INVESTMENT_SELECTED: {

      const newState = state.map(y => {
        const investment = y.incomes.find(i => i.category === 'investments');
        
        if(!y.hasOwnProperty("original")){
          y['original'] = {
            difference: y.difference,
            investment: investment && investment.net ? investment.net: 0,
            totalIncome: y.totalIncome
          };
        }
        
        y.incomes = updateIncomeInvestments(y.incomes, action.investment.value);

        if(investment) {
          y.difference = y.original.difference - y.original.investment + action.investment.value.pension;
          y.totalIncome = y.original.totalIncome - y.original.investment + action.investment.value.pension;
        } 

        return y;
      });

      return [...newState];
    }
    default:
      return state;
  }
}

export function shouldReloadYears(state = false, action) {
  switch (action.type) {
    case dashboardTypes.LOAD_YEARLY_OVERVIEW_SUCCESS:
      return false;
    case types.SAVE_SCENARIO_SUCCESS:
    case cjTypes.UPLOAD_TAX_SUCCESS:
    case cjTypes.UPLOAD_PENSION_SUCCESS:
    case cjTypes.UPLOAD_UPA_SUCCESS:
    case userTypes.CLEAR_DATA_SUCCESS:
    case cjTypes.SEND_NIBUD_CATEGORIES_SUCCESS:
    case ovTypes.MORTGAGE_SAVE_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function shouldReloadPersons(state = false, action) {
  switch (action.type) {
    case types.LOAD_PERSONS_SUCCESS:
      return false;
    case cjTypes.UPLOAD_TAX_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function shouldReloadUserData(state = false, action) {
  switch (action.type) {
    case dashboardTypes.LOAD_API_USER_SUCCESS:
    case cjTypes.GET_API_USER_SUCCESS:
      return false;
    case cjTypes.UPLOAD_TAX_SUCCESS:
    case cjTypes.UPLOAD_PENSION_SUCCESS:
    case cjTypes.UPLOAD_UPA_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function shouldReloadScenarios(state = false, action) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS:
      return false;
    case cjTypes.SEND_NIBUD_CATEGORIES_SUCCESS:
    case types.SET_SELECTED_SCENARIO:
      return true;
    default:
      return state;
  }
}

export function forecast(state = false, action) {
  switch (action.type) {
    case ovTypes.URM_INVESTMENT_SELECTED:
      return action.investment;
    default:
      return state;
  }
}

export default combineReducers({
  userData,
  years,
  shouldReloadYears,
  shouldReloadPersons,
  shouldReloadUserData,
  shouldReloadScenarios,
  reminderNotificationEmail,
  forecast
});
