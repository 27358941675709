export const LOAD_ORGANISATIONS_SUCCESS = 'LOAD_ORGANISATIONS_SUCCESS';
export const LOAD_PARENT_ORGANISATIONS_SUCCESS = 'LOAD_PARENT_ORGANISATIONS_SUCCESS';
export const LOAD_USERSLIST_SUCCESS = 'LOAD_USERSLIST_SUCCESS';
export const LOAD_EDIT_USER_SUCCESS = 'LOAD_EDIT_USER_SUCCESS';
export const LOAD_SELECTED_USER_SUCCESS = 'LOAD_SELECTED_USER_SUCCESS';
export const LOAD_USERS_GROUPS_SUCCESS = 'LOAD_USERS_GROUPS_SUCCESS';
export const LOAD_SELECTED_USER_GROUP_SUCCESS = 'LOAD_SELECTED_USER_GROUP_SUCCESS';
export const SEND_EDITED_USER_DATA_SUCCESS = 'SEND_EDITED_USER_DATA_SUCCESS';
export const ADD_SINGLE_USER_SUCCESS = 'ADD_SINGLE_USER_SUCCESS';
export const ADD_MULTIPLE_USERS_RESULT = 'ADD_MULTIPLE_USERS_RESULT';
export const ADD_MULTIPLE_USERS_PARSE_RESULT = 'ADD_MULTIPLE_USERS_PARSE_RESULT';
export const CLEAN_USERS_PARSE_RESULT = 'CLEAN_USERS_PARSE_RESULT';
export const DELETE_COGNITO_USER_SUCCESS = 'DELETE_COGNITO_USER_SUCCESS';
export const DISABLE_COGNITO_USER_SUCCESS = 'DISABLE_COGNITO_USER_SUCCESS';
export const ENABLE_COGNITO_USER_SUCCESS = 'ENABLE_COGNITO_USER_SUCCESS';
export const USERS_FILTER = 'USERS_FILTER';
export const CLEAR_USERS_FILTER = 'CLEAR_USERS_FILTER';
export const USERS_SORT = 'USERS_SORT';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const SAVE_ORGANISATION_SUCCESS = 'SAVE_ORGANISATION_SUCCESS';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';
export const DISABLE_ORGANISATION_SUCCESS = 'DISABLE_ORGANISATION_SUCCESS';
export const ENABLE_ORGANISATION_SUCCESS = 'ENABLE_ORGANISATION_SUCCESS';
export const ORGANISATION_FILTER = 'ORGANISATION_FILTER';
export const CLEAR_ORGANISATION_FILTER = 'CLEAR_ORGANISATION_FILTER';
export const DELETE_SINGLE_USERS_SUCCESS = 'DELETE_SINGLE_USERS_SUCCESS';
export const DISABLE_SINGLE_USERS_SUCCESS = 'DISABLE_SINGLE_USERS_SUCCESS';
export const ENABLE_SINGLE_USERS_SUCCESS = 'ENABLE_SINGLE_USERS_SUCCESS';

export const DELETE_MULTIPLE_USERS_SUCCESS = 'DELETE_MULTIPLE_USERS_SUCCESS';
export const DISABLE_MULTIPLE_USERS_SUCCESS = 'DISABLE_MULTIPLE_USERS_SUCCESS';
export const ENABLE_MULTIPLE_USERS_SUCCESS = 'ENABLE_MULTIPLE_USERS_SUCCESS';
export const CLOSE_BOTTOMBAR = 'CLOSE_BOTTOMBAR';
export const OPEN_BOTTOMBAR = 'OPEN_BOTTOMBAR';
export const SET_MULTIPLE_USERS_STEP = 'SET_MULTIPLE_USERS_STEP';

export const LOAD_AUDIT_LOGS_SUCCESS = 'LOAD_AUDIT_LOGS_SUCCESS';
export const AUDIT_LOGS_FILTER = 'AUDIT_LOGS_FILTER';
export const SET_AUDIT_FILTER_LOGS = 'SET_AUDIT_FILTER_LOGS';
export const SORT_AUDIT_LOGS = 'SORT_AUDIT_LOGS';

export const LOAD_EMAIL_TEMPALTES_SUCCESS = 'LOAD_EMAIL_TEMPALTES_SUCCESS';
export const EMAIL_TEMPLATES_FILTER = 'EMAIL_TEMPLATES_FILTER';
export const SET_EMAIL_TEMPLATES_FILTER = 'SET_EMAIL_TEMPLATES_FILTER';
export const SORT_EMAIL_TEMPLATES = 'SORT_EMAIL_TEMPLATES';
export const LOAD_EMAIL_TEMPALTE_SUCCESS = 'LOAD_EMAIL_TEMPALTE_SUCCESS';
export const SAVE_EMAIL_TEMPLATE_SUCCESS = 'SAVE_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS';

export const LOAD_USERS_BULK_IMPORT_SUCCESS = 'LOAD_USERS_BULK_IMPORT_SUCCESS';
export const LOAD_USERS_BULK_IMPORT_FAIL = 'LOAD_USERS_BULK_IMPORT_FAIL';
export const LOAD_USERS_BULK_IMPORT_DETAILS_SUCCESS = 'LOAD_USERS_BULK_IMPORT_DETAILS_SUCCESS';
export const CREATE_USERS_BULK_IMPORT_SUCCESS = 'CREATE_USERS_BULK_IMPORT_SUCCESS';
export const CLEAR_USERS_BULK_IMPORT = 'CLEAR_USERS_BULK_IMPORT';

export const ADD_MULTIPLE_USERS_SUCCESS = 'ADD_MULTIPLE_USERS_SUCCESS';
export const RESEND_INVITATION = 'RESEND_INVITATION';

export const WEEKLY_REPORT_SUCCESS = 'WEEKLY_REPORT_SUCCESS';
export const EMPLOYER_REPORT_SUCCESS = 'EMPLOYER_REPORT_SUCCESS';

export const LOAD_TOP_ORGANIZATIONS_SUCCESS = 'LOAD_TOP_ORGANIZATIONS_SUCCESS';
export const ENDORSER_REPORT_SUCCESS = 'ENDORSER_REPORT_SUCCESS';