// @flow

import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import { selectAuthStep } from 'actions/common';
import jwtDecode from 'jwt-decode';
import CustomError from './customError';

export const getEnvVar = (name: string, defaultVal: string = '') =>
  process.env[`REACT_APP_${name}`] || defaultVal;

const userPoolId = getEnvVar('USER_POOL_ID');
const clientId = getEnvVar('CLIENT_ID');
const awsRegion = getEnvVar('AWS_REGION');
const identityPoolId = getEnvVar('IDENTITY_POOL_ID');

const parseError = (error) => {
  const { name, message } = error;
  return { name, message };
};

class CognitoService {
  constructor() {
    this.userPool = new CognitoUserPool({
      ClientId: clientId,
      UserPoolId: userPoolId,
    });
    AWS.config.region = awsRegion;
    this.cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(
      {
        apiVersion: '2016-04-18',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
        }),
      }
    );
  }

  createCognitoUser({ email }) {
    const userData = {
      Username: email.toLowerCase(),
      Pool: this.userPool,
    };

    return new CognitoUser(userData);
  }

  signUp(data) {
    const attrEmail = new CognitoUserAttribute({
      Name: 'email',
      Value: data.email.toLowerCase(),
    });
    const attrBirthdate = new CognitoUserAttribute({
      Name: 'birthdate',
      Value: data.birthdate,
    });
    const attrGender = new CognitoUserAttribute({
      Name: 'gender',
      Value: data.gender,
    });
    const attrGivenName = new CognitoUserAttribute({
      Name: 'given_name',
      Value: data.given_name,
    });
    const attrName = new CognitoUserAttribute({
      Name: 'name',
      Value: data.name,
    });

    const attributeList = [
      attrBirthdate,
      attrEmail,
      attrGender,
      attrGivenName,
      attrName,
    ];

    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        data.email.toLowerCase(),
        '123456=A',
        attributeList,
        null,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  signIn({ email, password }, dispatch) {
    const applyPromise = (reduxDispatcher = dispatch) => {
      return new Promise((resolve, reject) => {
        if (!email || !password) {
          reject(new Error({ message: 'Email and password are required!' }));
          return;
        }
        const lowerCaseEmail = email.toLowerCase();
        const authenticationData = {
          Username: lowerCaseEmail,
          Password: password,
        };

        const authenticationDetails = new AuthenticationDetails(
          authenticationData
        );
        const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (error) => {
            reject(parseError(error));
          },

          newPasswordRequired: function (result) {
            reduxDispatcher({ type: 'SIGN_IN_SUCCESS', userData: result });
            reduxDispatcher({
              type: 'SHOW_NEW_PASSWORD',
              showNewPassword: true,
            });
            reduxDispatcher(selectAuthStep('changePassword'));
          },
        });
      });
    };
    return applyPromise();
  }

  resetPassword({ email, code, password }) {
    return new Promise((resolve, reject) => {
      if (!email || !password) {
        reject(
          new Error({ message: 'Email, code and password are required!' })
        );
        return;
      }

      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });

      cognitoUser.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (error) => {
          reject(parseError(error));
        },
      });
    });
  }

  changePassword(values) {
    return new Promise((resolve, reject) => {
      if (!values.email || !values.tempPassword) {
        reject(new Error({ message: 'Email and password are required!' }));
        return;
      }
      const lowerCaseEmail = values.email.toLowerCase();
      const authenticationData = {
        Username: lowerCaseEmail,
        Password: values.tempPassword,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (error) => {
          reject(parseError(error));
        },
        // eslint-disable-next-line
        newPasswordRequired: function (result) {
          cognitoUser.completeNewPasswordChallenge(
            values.newPassword,
            {},
            this
          );
        },
      });
    });
  }

  forgotPassword({ email }) {
    return new Promise((resolve, reject) => {
      if (!email) {
        reject(new Error({ message: 'Email is required!' }));
        return;
      }
      const lowerCaseEmail = email.toLowerCase();
      const cognitoUser = this.createCognitoUser({ email: lowerCaseEmail });
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (error) => {
          reject(parseError(error));
        },
      });
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new CustomError(403, 'No user found'));
        return;
      }
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }
        const refreshToken = session.getRefreshToken();
        cognitoUser.refreshSession(refreshToken, (err, refSession) => {
          if (err) {
            reject(new Error({ message: err }));
          } else {
            const userId = jwtDecode(refSession.getIdToken().getJwtToken()).sub;
            window.localStorage[
              `CognitoIdentityServiceProvider.${clientId}.${userId}.idToken`
            ] = refSession.getIdToken().getJwtToken();
            window.localStorage[
              `CognitoIdentityServiceProvider.${clientId}.${userId}.accessToken`
            ] = refSession.getAccessToken().getJwtToken();
            window.localStorage[
              `CognitoIdentityServiceProvider.${clientId}.${userId}.refreshToken`
            ] = refSession.getRefreshToken().getToken();
            resolve(refSession);
          }
        });
      });
    });
  }

  // current user
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new CustomError(403, 'invalid_token'));
        return;
      }

      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        cognitoUser.getUserAttributes((_error, result) => {
          if (_error) {
            reject(parseError(_error));
            return;
          }
          const sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);
          const user = {
            id_token: session.getIdToken().getJwtToken(),
            access_token: session.getAccessToken().getJwtToken(),
            refresh_token: session.getRefreshToken().getToken(),
            user_group: sessionIdInfo['cognito:groups'],
          };

          for (let i = 0; i < result.length; i += 1) {
            user[result[i].getName()] = result[i].getValue();
          }

          resolve(user);
        });
      });
    });
  }

  addUserToGroup(groupName) {
    const params = {
      GroupName: groupName,
      UserPoolId: userPoolId,
      Username: this.userPool.getCurrentUser().username,
    };
    return new Promise((resolve, reject) => {
      this.cognitoIdentityServiceProvider.adminAddUserToGroup(
        params,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  resendConfirmationCode(data) {
    const params = {
      MessageAction: 'RESEND',
      UserPoolId: userPoolId,
      Username: data.email,
    };
    return new Promise((resolve, reject) => {
      this.cognitoIdentityServiceProvider.adminCreateUser(
        params,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  adminGetUser(data) {
    const params = {
      UserPoolId: userPoolId,
      Username: data.email,
    };
    return new Promise((resolve, reject) => {
      this.cognitoIdentityServiceProvider.adminGetUser(
        params,
        (error, result) => {
          if (error) {
            reject(parseError(error));
            return;
          }
          resolve(result);
        }
      );
    });
  }

  getTokens() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        reject(new CustomError(403, 'No user found'));
        return;
      }

      // getSession() loads valid tokens cached in the local store
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(parseError(error));
          return;
        }

        const tokens = {
          id_token: session.getIdToken().getJwtToken(),
          access_token: session.getAccessToken().getJwtToken(),
          refresh_token: session.getRefreshToken().getToken(),
        };

        resolve(tokens);
      });
    });
  }

  signOut() {
    return new Promise((reselve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(() => {
          cognitoUser.globalSignOut({
            onSuccess: (msg) => reselve(msg),
            onFailure: (err) => reject(err),
          });
        });
      }
    });
  }
}

export default new CognitoService();
